import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { ChartOptions } from "chart.js";
import {ActivityType, StatisticItem} from "../../../../../model/activity.model";
import convertDecimalToHexColor from "../../../../../utils/convertDecimalToHexColor";
import { formattedDuration } from "../../../../../utils/dateTime";

dayjs.extend(duration);

export const getPieChartData = (statisticItems: StatisticItem[], activityTypes: ActivityType[]) => {
  const summary = statisticItems.reduce<Record<string, { duration: number, color: string }>>((summary, statisticItem) => {
    const color = convertDecimalToHexColor(statisticsItemColor(activityTypes, statisticItem));
    summary[statisticItem.types.join('+')] = {
      color,
      duration: statisticItem.duration
    }
    return summary;
  }, {});

  return {
    labels: statisticItems.map(item => statisticsItemTitle(activityTypes, item)),
    datasets: [
      {
        data: statisticItems.map(item => summary[item.types.join('+')]?.duration),
        backgroundColor: statisticItems.map(item => summary[item.types.join('+')]?.color),
        borderWidth: 1,
      },
    ],
  };
}

export const makePieChartOptions = (statisticItems: StatisticItem[], animated: Boolean): ChartOptions<'doughnut'> => ({
  animation: animated,
  plugins: {
    tooltip: {
      callbacks: {
        label(tooltipItem): string {
          return `${tooltipItem.label}: ${formattedDuration(tooltipItem.dataset.data[tooltipItem.dataIndex] * 1000)}`;
        }
      }
    },
    legend: {
      display: statisticItems.length < 15,
      labels: {
        usePointStyle: true,
        boxWidth: 10,
        boxHeight: 10,
      },
      position: "bottom"
    }
  },
})

export const statisticsItemTitle = (allTypes: ActivityType[], item: StatisticItem): string => {
  return item.types.flatMap(value => {
    return allTypes?.find(type => type.id === value)?.name || ''
  }).join('+')
}

export const statisticsItemColor = (allTypes: ActivityType[], item: StatisticItem): number => {
  let sum =  item.types.flatMap(value => {
    return allTypes?.find(type => type.id === value)?.color || 0
  }).reduce((sum, current) => sum + current, 0)

  return sum / item.types.length
}

export interface DataType {
  key: React.ReactNode,
  name: string,
  duration: string,
  color: string,
  children?: DataType[]
}

export const convertToTableTreeData = (groupedStatistics: StatisticItem[], types: ActivityType[]): DataType[] => {
  return groupedStatistics.map((statItem: StatisticItem): DataType => {
    const children = statItem.children && statItem.children.length > 0  ? convertToTableTreeData(statItem.children, types) : undefined;

    return {
      key: statItem.types.join(", "),   // Use 'types' to create a unique key
      name: `${statisticsItemTitle(types, statItem)}`,   // You can customize how the name is displayed
      duration: `${formattedDuration(statItem.duration * 1000)}`,
      color: `${convertDecimalToHexColor(statisticsItemColor(types, statItem))}`,
      children: children,  // Recursively map children if they exist
    };
  });
}
