import {Tabs, TabsProps} from 'antd';
import React, { FC, useCallback } from 'react';
import {useIntl} from "react-intl";
import {BarChartOutlined, PieChartOutlined, UnorderedListOutlined} from "@ant-design/icons";

export const getViewSwitchOptions = (formatMessage: any) => ([
  {
    label: formatMessage({ id: 'HISTORY' }),
    key: 'History',
    icon: <UnorderedListOutlined/>
  },
  {
    label: formatMessage({ id: 'GROUPED' }),
    key: 'Summary',
    icon: <BarChartOutlined/>
  },
  {
    label: formatMessage({ id: 'TOTAL' }),
    key: 'Total',
    icon: <PieChartOutlined/>
  }
]);

interface IViewSwitch {
  setView: React.Dispatch<string>
  view: string
}
export const ViewSwitch: FC<IViewSwitch> = ({ setView, view }) => {
  const { formatMessage } = useIntl();
  const onChange = useCallback((e: string) => setView(e), [setView]);

  const items: TabsProps['items'] = getViewSwitchOptions(formatMessage)

  return (
      <Tabs style={{ width: 300 }} items={items} onChange={onChange}/>
  )
}
