import {AxiosError} from "axios";
import dayjs from "dayjs";
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';

import {create} from 'zustand';

import {ActivityState, GetIntervalsParams, InitialActivityState} from "../model/activity.model";
import {ActivityService} from "../api/activity.service";
import {DATE_FORMAT} from "../constant/format";
import {downloadFile} from "../utils/downloadFile";
import {GroupBy} from "../enum/activity.enum";
import {authStore} from "./auth.store";
import {deepCopy} from "../utils/deepCopy";
import {getPaginationFetchMethods, getSimpleFetchMethods} from "./utils/asyncZustandMethods";

dayjs.extend(weekday);
dayjs.extend(localeData);

function getFirstDayOfWeek(): number {
  const browserLocale: string = navigator.language || 'en-US';
  const options: Intl.DateTimeFormatOptions = { weekday: 'short' };
  const formatter = new Intl.DateTimeFormat(browserLocale, options);

  // Known Sunday date (Jan 7, 2024)
  const sunday = new Date(Date.UTC(2024, 0, 7));
  const parts = formatter.formatToParts(sunday);
  const isSundayFirst = parts.find(part => part.type === 'weekday')?.value === 'S';

  return isSundayFirst ? 0 : 1; // 0 = Sunday, 1 = Monday
}

// Get first day of the week based on browser locale
const firstDayOfWeek: number = getFirstDayOfWeek();

// Calculate start and end dates dynamically
const initialStartDate: string = dayjs().weekday(firstDayOfWeek).startOf('day').format(DATE_FORMAT);
const initialEndDate: string = dayjs().weekday(firstDayOfWeek + 6).endOf('day').format(DATE_FORMAT);

const initialState: InitialActivityState = {
  filtersConfiguration: null,
  activityTypes: null,
  activities: null,
  intervalFilters: null,
  intervalToEdit: null,
  activityToEdit: null,
  activityToCreate: null,
  activityDetails: null,
  activityTags: null,

  intervalsParams: {
    from: initialStartDate,
    to: initialEndDate,
    tags: [],
    groupBy: GroupBy.DAY
  },
  intervalsPagination: {
    page: 0
  },
  intervalsPaginationInfo: {
    last: true
  },

  tagsParams: {
    query: ''
  },
  activityTagsParams: {
    query: ''
  },
  tagsPagination: {
    page: 0
  },
  tagsPaginationInfo: {
    last: true
  },

  daysIntervals: null,
  statistics: null,
  loading: {
    getActivityDetails: false,
    getActivityTypes: false,
    getIntervalsFilters: false,
    getIntervalsStartPage: false,
    getNextActivityPage: false,
    getNextIntervalsPage: false,
    getStatistics: false,
    getActivities: false,
    startActivity: false,
    stopActivity: false,
    pauseActivity: false,
    resumeActivity: false,
    rewriteInterval: false,
    deleteInterval: false,
    receiveExport: false,
    getTagsStartPage: false,
    getNextTagsPage: false,
    getActivityTagsStartPage: false,
    getActivityNextTagsPage: false,
    rewriteActivity: false,
    createActivity: false,
    createFilter: false,
    deleteFilter: false,
    updateFilter: false
  },
  error: {
    getActivityDetails: null,
    getActivityTypes: null,
    getIntervalsFilters: null,
    getIntervalsStartPage: null,
    getNextIntervalsPage: null,
    getStatistics: null,
    getActivities: null,
    startActivity: null,
    stopActivity: null,
    pauseActivity: null,
    resumeActivity: null,
    rewriteInterval: null,
    deleteInterval: null,
    receiveExport: null,
    getTagsStartPage: null,
    getNextTagsPage: null,
    getActivityTagsStartPage: null,
    getActivityNextTagsPage: null,
    rewriteActivity: null,
    createActivity: null,
    createFilter: null,
    deleteFilter: null,
    updateFilter: null
  },
};

export const activityStore = create<ActivityState>((set, get) => ({
  setFiltersConfiguration: (filtersConfiguration) => set({ filtersConfiguration }),
  setIntervalToEdit: (interval) => set({ intervalToEdit: interval }),
  setActivityToEdit: (activity) => set({ activityToEdit: deepCopy(activity) }),
  setActivityToCreate: (activity) => set({ activityToCreate: deepCopy(activity) }),
  updateFilter: async (filter) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'updateFilter');

    try {
      startLoading();

      await ActivityService.updateFilter(filter);

      await get().getIntervalsFilters();

      const updatedFilter = get().intervalFilters?.find((e) => e.id === filter.id);

      updatedFilter && get().setFiltersConfiguration(updatedFilter.name);

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>;
      endLoadingWithError(error);
    }
  },
  deleteFilter: async (id) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'deleteFilter');

    try {
      startLoading();

      await ActivityService.deleteFilter(id);

      await get().getIntervalsFilters();

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>;
      endLoadingWithError(error);
    }
  },
  createFilter: async (filter) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'createFilter');

    try {
      startLoading();

      await ActivityService.createFilter(filter);

      await get().getIntervalsFilters();

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>;
      endLoadingWithError(error);
    }
  },

  createActivity: async (activity) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'createActivity');

    try {
      startLoading();

      await ActivityService.createActivity(activity);

      get().setActivityToCreate(null);

      await Promise.all([get().getStatistics(), get().getIntervalsStartPage(), get().getActivities()]);

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>;
      endLoadingWithError(error);
    }
  },
  setTagsParams: (params) => set({ tagsParams: { ...get().tagsParams, ...params } }),
  setActivityTagsParams: (params) => set({activityTagsParams : { ...get().activityTagsParams, ...params } }),

  getTagsStartPage: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getPaginationFetchMethods(activityStore, 'getTagsStartPage');
    try {
      const fieldsToSet = {
        intervalsPagination: initialState.tagsPagination
      };

      startLoading(fieldsToSet);

      const getTagsRes = await ActivityService.getActivityTags({ ...get().tagsPagination, ...get().tagsParams });

      endLoadingWithSuccess({ intervalsPaginationInfo: { last: getTagsRes.data.last } }, 'activityTags', getTagsRes.data.content);
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  getNextTagsPage: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getPaginationFetchMethods(activityStore, 'getNextTagsPage');
    try {
      const fieldsToSet = {
        tagsPagination: {
          page: Number(get().tagsPagination.page + 1)
        }
      };

      startLoading(fieldsToSet);

      const getTagsRes = await ActivityService.getActivityTags({ ...get().tagsPagination, ...get().tagsParams });

      const existingData = get().activityTags || []

      endLoadingWithSuccess({ tagsPaginationInfo: { last: getTagsRes.data.last } }, 'activityTags', [...existingData, ...getTagsRes.data.content]);
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  getActivityTagsStartPage: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getPaginationFetchMethods(activityStore, 'getActivityTagsStartPage');
    try {
      const fieldsToSet = {
        intervalsPagination: initialState.tagsPagination
      };

      startLoading(fieldsToSet);

      const getTagsRes = await ActivityService.getActivityTags({ ...get().tagsPagination, ...get().activityTagsParams });

      endLoadingWithSuccess({ intervalsPaginationInfo: { last: getTagsRes.data.last } }, 'activityTags', getTagsRes.data.content);
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  getActivityNextTagsPage: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getPaginationFetchMethods(activityStore, 'getActivityNextTagsPage');
    try {
      const fieldsToSet = {
        tagsPagination: {
          page: Number(get().tagsPagination.page + 1)
        }
      };

      startLoading(fieldsToSet);

      const getTagsRes = await ActivityService.getActivityTags({ ...get().tagsPagination, ...get().activityTagsParams });

      const existingData = get().activityTags || []

      endLoadingWithSuccess({ tagsPaginationInfo: { last: getTagsRes.data.last } }, 'activityTags', [...existingData, ...getTagsRes.data.content]);
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  getActivityDetails: async (activityId, onSuccess) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'getActivityDetails');

    try {
      startLoading();

      const getActivityDetailsRes = await ActivityService.getActivity(activityId);

      if (onSuccess) {
        onSuccess(getActivityDetailsRes.data);
      }

      endLoadingWithSuccess('activityDetails', getActivityDetailsRes.data);
    } catch (err) {
      const error = err as AxiosError<Error>;

      endLoadingWithError(error);
    }
  },
  receiveExport: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'receiveExport');
    try {
      startLoading();

      const params = get().intervalsParams;
      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const receiveExportRes = params.from && params.to && userTimezone && await ActivityService.receiveExport({ from: params.from, to: params.to, timezone: userTimezone });

      if (receiveExportRes) {
        downloadFile(receiveExportRes.data)
      }

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>;
      endLoadingWithError(error);
    }
  },
  deleteInterval: async (interval) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'deleteInterval');
    try {
      startLoading();

      await ActivityService.deleteInterval(interval);

      await Promise.all([get().getStatistics(), get().getIntervalsStartPage(), get().getActivities()]);

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>;

      endLoadingWithError(error);
    }
  },
  rewriteActivity: async (activity) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'rewriteActivity');
    try {
      startLoading();

      await ActivityService.rewriteActivity(activity)

      get().setActivityToEdit(null);

      await Promise.all([get().getStatistics(), get().getIntervalsStartPage(), get().getActivities(), get().getTagsStartPage()]);

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  rewriteInterval: async (interval) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'rewriteInterval');
    try {
      startLoading();

      await ActivityService.rewriteInterval(interval);

      get().setIntervalToEdit(null);

      await Promise.all([get().getStatistics(), get().getIntervalsStartPage()]);

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  resumeActivity: async (typeId) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'resumeActivity');
    try {
      startLoading();

      await ActivityService.resumeActivity(typeId, Math.floor(Date.now() / 1000));

      await get().getActivities();

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  pauseActivity: async (typeId) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'pauseActivity');
    try {
      startLoading();

      await ActivityService.pauseActivity(typeId, Math.floor(Date.now() / 1000));

      await get().getActivities();

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  stopActivity: async (typeId) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'stopActivity');
    try {
      startLoading();

      await ActivityService.stopActivity(typeId, Math.floor(Date.now() / 1000));

      await get().getActivities();

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  refreshData: async() => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'refreshData');

    try {
      startLoading();

      await Promise.all([get().getStatistics(), get().getIntervalsStartPage(), get().getActivities()]);

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>;
      endLoadingWithError(error);
    }
  },

  startActivity: async (typeId) => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'startActivity');
    try {
      startLoading();

      await ActivityService.startActivity(typeId, Math.floor(Date.now() / 1000));

      endLoadingWithSuccess();
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  setIntervalsParams: (params: Partial<GetIntervalsParams>) => {
    set({
      intervalsParams: {
        ...get().intervalsParams,
        ...params
      }
    })
  },
  getActivities: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'getActivities');
    try {
      startLoading();

      const getActivitiesRes = await ActivityService.getActivities();

      endLoadingWithSuccess('activities', getActivitiesRes.data.activities);
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  getStatistics: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'getStatistics');
    try {
      startLoading()

      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const getStatisticsRes = await ActivityService.getStatistics({ ...get().intervalsParams, groupBy: get().intervalsParams.groupBy || GroupBy.DAY, timezone: userTimezone });

      endLoadingWithSuccess('statistics', getStatisticsRes.data);
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  getIntervalsFilters: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'getIntervalsFilters');
    try {
      startLoading();

      const intervalsFilters = await ActivityService.getIntervalsFilters();

      endLoadingWithSuccess('intervalFilters', intervalsFilters.data)
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  getIntervalsStartPage: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getPaginationFetchMethods(activityStore, 'getIntervalsStartPage');
    try {
      const fieldsToSet = {
        intervalsPagination: initialState.intervalsPagination
      };

      startLoading(fieldsToSet);

      const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const getIntervalsRes = await ActivityService.getIntervals({ ...get().intervalsParams, timezone: userTimezone }, get().intervalsPagination);

      endLoadingWithSuccess({ intervalsPaginationInfo: { last: getIntervalsRes.data.last } }, 'daysIntervals', getIntervalsRes.data.content);
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  getNextIntervalsPage: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getPaginationFetchMethods(activityStore, 'getNextIntervalsPage');
    try {
      const fieldsToSet = {
        intervalsPagination: {
          page: Number(get().intervalsPagination.page + 1)
        }
      };

      startLoading(fieldsToSet);

      const userTimezone = authStore.getState().user?.timeZone;

      const getIntervalsRes = await ActivityService.getIntervals({ ...get().intervalsParams, timezone: userTimezone }, get().intervalsPagination);

      const existingData = get().daysIntervals || []

      endLoadingWithSuccess({ intervalsPaginationInfo: { last: getIntervalsRes.data.last } }, 'daysIntervals', [...existingData, ...getIntervalsRes.data.content]);
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  getActivityTypes: async () => {
    const [startLoading, endLoadingWithSuccess, endLoadingWithError] = getSimpleFetchMethods(activityStore, 'getActivityTypes');
    try {
      startLoading();

      const activityTypesRes = await ActivityService.getActivityTypes();

      endLoadingWithSuccess('activityTypes', activityTypesRes.data);
    } catch (err) {
      const error = err as AxiosError<Error>
      endLoadingWithError(error);
    }
  },
  ...initialState,
}));
