import React, {useCallback, useEffect, useState} from "react";
import {Button, Col, Modal, Row, TreeSelect} from "antd";
import {useIntl} from "react-intl";
import {activityStore} from "../../../../store/activity.store";
import CurrentActivitiesList from "../CurrentActivitiesList/CurrentActivitiesList";
import style from "./style.module.css";
import {getActivityTypeOptions} from "./helper";
import EditActivityForm from "../EditActivityForm/EditActivityForm";
import {useForm} from "antd/es/form/Form";
import {authStore} from "../../../../store/auth.store";

const AuthoredHeader = () => {
  const {formatMessage} = useIntl();
  const getActivityTypes = activityStore(state => state.getActivityTypes)
  const getActivities = activityStore(state => state.getActivities);
  const startActivity = activityStore(state => state.startActivity);
  const getTagsStartPage = activityStore(state => state.getTagsStartPage);
  const setActivityToEdit = activityStore(state => state.setActivityToEdit);
  const activityToEdit = activityStore(state => state.activityToEdit);
  const activityTypes = activityStore(state => state.activityTypes || []);
  const activitiesTypesLoading = activityStore(state => state.loading.getActivityTypes);
  const activitiesLoading = activityStore(state => state.loading.getActivities);
  const tagsLoading = activityStore(state => state.loading.getTagsStartPage);
  const rewriteActivityLoading = activityStore(state => state.loading.rewriteActivity);
  const query = activityStore(state => state.tagsParams.query);
  const settings = authStore(state => state.user?.settings);

  const [form] = useForm();

  const [option, setOption] = useState<string | undefined>();

  useEffect(() => {
    !activitiesTypesLoading && getActivityTypes();
    !activitiesLoading && getActivities();
  }, []);

  useEffect(() => {
    !tagsLoading && getTagsStartPage();
  }, [query]);

  const onSelectChangeHandler = (option: string) => setOption(option);
  const startActivityHandler = () => option && startActivity(option)
    .then(() => {
      getActivities();
      setOption(undefined);
    });

  const filterTreeNodeCallback = useCallback((inputValue: string, treeNode: any) => !inputValue
    ? true
    : treeNode.name.toLowerCase().includes(inputValue.toLowerCase()
    ), []);

  const onOkHandler = useCallback(() => form.submit(), [form]);

  const cancelHandler = useCallback(() => setActivityToEdit(null), [setActivityToEdit]);

  return (
    <div className={style.AuthoredHeader}>
      <Row justify="space-between"
           gutter={[16, 16]}
           style={{width: '100%'}}>
        <Col >
          <div className={style.HeaderActions}>
            <TreeSelect
              className={style.select}
              filterTreeNode={filterTreeNodeCallback}
              loading={activitiesTypesLoading}
              showSearch
              onChange={onSelectChangeHandler}
              value={option}
              treeData={getActivityTypeOptions(activityTypes)}
              placeholder={formatMessage({id: 'SELECT_ACTIVITY'})}
            />
            <Button
              onClick={startActivityHandler}
            >
              {formatMessage({id: 'START_ACTIVITY'})}
            </Button>
          </div>
        </Col>
        <Col style={{textAlign: 'right'}}>
          <CurrentActivitiesList settings={settings}/>
        </Col>
      </Row>
      <Modal
        title={formatMessage({id: 'EDIT_ACTIVITY'})}
        open={!!activityToEdit}
        onOk={onOkHandler}
        okText={formatMessage({id: 'SAVE'})}
        onCancel={cancelHandler}
        confirmLoading={rewriteActivityLoading}
      >
        {!!activityToEdit && <EditActivityForm activity={activityToEdit} form={form}/>}
      </Modal>
    </div>
  )
}

export default AuthoredHeader;
