
import {Doughnut} from "react-chartjs-2";
import {
  convertToTableTreeData,
  DataType,
  getPieChartData,
  makePieChartOptions
} from "./helper";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { activityStore } from "../../../../../store/activity.store";
import {Col, Row, Table} from "antd";
import style from './style.module.css';
import React from "react";
import {formattedDuration} from "../../../../../utils/dateTime";
import Column from "antd/es/table/Column";
import {useLocalStorage} from "react-use";
import {Settings} from "../../../../../model/auth.model";
import {APP_SETTINGS_LS_KEY} from "../../../../../constant/key";

ChartJS.register(ArcElement, Tooltip, Legend);

const ActivityPie = () => {
  const statistics = activityStore(state => state.statistics);

  const [settings] = useLocalStorage<Settings | null>(
    APP_SETTINGS_LS_KEY,
    null
    // ,{ raw: true }
  );

  const grouped = settings?.displayGroupsInBarStatistics ?? false
  const animated = true;

  return (
    statistics && (
      <Row>
        <Col lg={12} sm={24} xs={24}>
          <div className={style.ActivityPie}>
            <Doughnut
              style={{ maxHeight: 300, maxWidth: 300 }}
              data={getPieChartData(
                grouped ? statistics.total.groupedStatistics : statistics.total.statistics,
                statistics.types)}
              options={makePieChartOptions(
                grouped ? statistics.total.groupedStatistics : statistics.total.statistics,
                animated
              )}
            />
          </div>
        </Col>
        <Col lg={12} sm={24} xs={24}>

          <Row justify='space-between' className={style.DaysIntervalContent}>
            <Row className={style.InfoRow}>
              <Col>
                <h3>Total</h3>
              </Col>
            </Row>
            <Row justify='end' className={style.InfoRow}>
              <Col><h3>{formattedDuration(statistics.total.info.total * 1000)}</h3></Col>
            </Row>
          </Row>

          <Table<DataType>
            // rowSelection={{ ...rowSelection, checkStrictly }}
            dataSource={convertToTableTreeData(grouped ? statistics.total.groupedStatistics : statistics.total.statistics, statistics.types)} showHeader={false}
            pagination={{ position : ['none']}}
          >
            <Column className={style.TableListRow} title="Name" key="types" render={ (types) => (
              <div>
                <Row justify='space-between' className={style.DaysIntervalContent}>
                  <Row className={style.InfoRow}>
                    <Col className={style.TypeLabel}>
                      <div
                        style={{
                          background: `${types.color}`
                        }}
                        className={style.ActivityTypesFilterLabelColor}
                      />
                    </Col>
                    <Col className={style.TypeLabel}>
                      {types.name}
                    </Col>
                  </Row>
                  <Row justify='end'>
                    <Col><div className="duration">{types.duration}</div></Col>
                  </Row>
                </Row>
              </div>
            )}/>
          </Table>

        </Col>

      </Row>

    )
  );
}

export default ActivityPie;
