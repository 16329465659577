import {Button, Input, Modal} from "antd";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";

import {activityStore} from "../../../../../store/activity.store";

const CreateFilterButton = () => {
  const { formatMessage } = useIntl();
  const { types, tags} = activityStore(state => state.intervalsParams);
  const createFilter = activityStore(state => state.createFilter);
  const setFiltersConfiguration = activityStore(state => state.setFiltersConfiguration);
  const loading = activityStore(state => state.loading.createFilter);
  const [isOpen, setIsOpen] = useState(false);
  const [filterName, setFilterName] = useState('');

  const createFilterHandler = useCallback(() => {
    const filter = {
      name: filterName,
      types,
      tags
    };

    void createFilter(filter).then(() => {
      setIsOpen(false);
      setFiltersConfiguration(filter.name)
    });
  }, [filterName, types, tags, createFilter]);

  return <>
    <Button
      onClick={() => setIsOpen(true)}
    >
      {formatMessage({ id: 'SAVE_FILTER_CONFIGURATION' })}
    </Button>
    <Modal
      title={formatMessage({ id: 'CREATE_FILTER_CONFIGURATION' })}
      onCancel={() => setIsOpen(false)}
      open={isOpen}
      onOk={createFilterHandler}
      okText={formatMessage({id: 'SAVE'})}
      confirmLoading={loading}
    >
      <br/>
      <Input
        value={filterName}
        placeholder={formatMessage({ id: 'FILTER_NAME_PLACEHOLDER'})}
        onChange={(e) => setFilterName(e.target.value)}
      />
    </Modal>
  </>
}

export default CreateFilterButton;
