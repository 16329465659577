import {ActivityType} from "../model/activity.model";

const getTypeFullPath = (activityType: ActivityType | undefined, activityTypes: ActivityType[] | undefined | null): string => {
  if (!activityType || !activityTypes) {
    return 'Unknown Activity Type'; // Default fallback if either is undefined
  }

  if (!activityType || !activityTypes) {
    return 'Unknown Activity Type'; // Default fallback if either is undefined
  }

  if (!activityType.parentId) {
    return activityType.name; // Or any desired fallback for root-level activity types
  }

  const breadcrumbs: string[] = [];
  let currentType: ActivityType | undefined = activityType;

  while (currentType) {
    breadcrumbs.unshift(currentType.name);

    // Find the parent type or break if not found
    currentType = activityTypes.find(
      (type): type is ActivityType => !!type && type.id === currentType?.parentId
    );
  }

  return breadcrumbs.length > 0 ? breadcrumbs.join('/') : '';
};

export default getTypeFullPath;
