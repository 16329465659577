import React, {useCallback} from 'react';
import {Button, Col, Row} from "antd";
import {DownloadOutlined} from "@ant-design/icons";

import style from "../../style.module.css";
import HistoryList from "../HistoryList/HistoryList";
import { useIntl } from "react-intl";
import { Interval } from "../../../../../model/activity.model";
import { ActivityStatus } from "../../../../../enum/activity.enum";
import { activityStore } from "../../../../../store/activity.store";

export const RecordsHistory = () => {
  const { formatMessage } = useIntl();
  const setActivityToCreate = activityStore(state => state.setActivityToCreate);
  const receiveExport = activityStore(state => state.receiveExport);
  const daysIntervals = activityStore(state => state.daysIntervals || []);
  const receiveExportLoading = activityStore(state => state.loading.receiveExport);

  const openModalToForCreateActivity = useCallback(() => {
    const interval = { start: new Date().toISOString(), finish: new Date().toISOString() } as Interval;
    const newActivity = {
      status: ActivityStatus.STOPPED,
      start: '',
      intervals: [ interval ],
      tags: [],
      typeId: ''
    };

    setActivityToCreate(newActivity);
  }, [setActivityToCreate]);

  const isDataExist = !!daysIntervals?.length;

  return <>
      <Row justify='space-between'>
      <label className={style.HistoryLabel}>
      </label>
      <Row className={style.HistoryActions}>
        {isDataExist && (
          <Button
            onClick={receiveExport}
            loading={receiveExportLoading}
          >
            {formatMessage({id: 'DOWNLOAD_HISTORY'})}
            <DownloadOutlined/>
          </Button>
        )}
        <Button onClick={openModalToForCreateActivity}>Add record</Button>
      </Row>
    </Row>
    <Row>
      <Col span={24}><HistoryList /></Col>
    </Row>
  </>
}
